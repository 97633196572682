import React, { useState, useEffect } from "react";
import SearchBar from "./SeacrhBar";
import youtube from "../apis/youtube";
import VideoList from "./VideoList";
import VideoDetail from "./VideoDetail";
import useVideo from "../hooks/useVideo";

const App = () => {
    const [videos, search] = useVideo("humdard");
    const [onVideoSelect, setVideoSelect] = useState(null);

    useEffect(() => {
        setVideoSelect(videos[0]);
    }, [videos])

    const videoSelect = (video) => {
        setVideoSelect(video);
    }

    return (
        <div className="ui container">
            <SearchBar onFormSubmit={search} />
            <div className="ui grid">
                <div className="ui row">
                    <div className="eleven wide column"><VideoDetail video={onVideoSelect} /></div>
                    <div className="five wide column"> <VideoList onVideoSelected={videoSelect} videos={videos} /></div>
                </div>
            </div>

        </div>
    );
}

// class App extends React.Component {
//     state = { videos: [], onVideoSelect: null };
//     onTermSubmit = async (term) => {
//         const response = await youtube.get("/search", {
//             params: {
//                 q: term
//             }
//         });
//         this.setState({ videos: response.data.items });
//     }

//     onVideoSelect = video => {
//         console.log(video);
//         this.setState({ onVideoSelect: video })
//     }

//     render() {
//         return (
//             <div className="ui container">
//                 <SearchBar onFormSubmit={this.onTermSubmit} />
//                 <div className="ui grid">
//                     <div className="ui row">
//                         <div className="eleven wide column"><VideoDetail video={this.state.onVideoSelect} /></div>
//                         <div className="five wide column"> <VideoList onVideoSelected={this.onVideoSelect} videos={this.state.videos} /></div>
//                     </div>
//                 </div>

//             </div>

//         )
//     }
// }

export default App;