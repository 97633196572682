import React from "react";
import VideoItems from "./VideoItems";

const VideoList = ({ videos, onVideoSelected }) => {
    const renderList = videos.map(video => {
        return <VideoItems onVideoSelect={onVideoSelected} key={video.etag} video={video} />
    })

    return (
        <div className="ui relaxed divided list">{renderList}</div>
    );
};

export default VideoList;