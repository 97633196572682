import React from "react";

const VideoItems = ({ video, onVideoSelect }) => {
    console.log(video);
    return (<div className="item" onClick={() => onVideoSelect(video)}>
        <img className="ui image" src={video.snippet.thumbnails.medium.url} />
        <div className="content">
            <a className="header">{video.snippet.title}</a>
            <div className="description">{video.snippet.description}</div>
        </div>
    </div>)
}

export default VideoItems;