import React, { useState } from "react";

const SearchBar = ({onFormSubmit}) => {

    const [term, setTerm] = useState("");

    const formSubmit = (event) => {
        event.preventDefault();
        onFormSubmit(term)
    }

    return (
        <div className="search-bar ui segment">
            <form onSubmit={formSubmit} className="ui form">
                <div className="field">
                    <label>Search</label>
                    <input type="text" value={term} onChange={(e) => setTerm(e.target.value)} />
                </div>
            </form>
        </div>
    );
}




// class SearchBar extends React.Component {
//     state = { term: "" };

//     onInputChange = (event) => {
//         this.setState({ term: event.target.value });
//     }

//     formSubmit = (event) => {
//         event.preventDefault();
//         this.props.onFormSubmit(this.state.term)
//     }

//     render() {
//         return (
//             <div className="search-bar ui segment">
//                 <form onSubmit={this.formSubmit} className="ui form">
//                     <div className="field">
//                         <label>Search</label>
//                         <input type="text" value={this.state.term} onChange={this.onInputChange} />
//                     </div>
//                 </form>
//             </div>
//         )
//     }
// }

export default SearchBar;