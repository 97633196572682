import axios from "axios";

const KEY = "AIzaSyAUPENrcY_ukPvnEDSXJwi1ETlnF8T4OrY";
export default axios.create({
    params:{
        part: 'snippet', 
        type: 'video',
        maxResults: 12,
        key: KEY
    },
    baseURL: "https://www.googleapis.com/youtube/v3"
});
